import React from 'react'

import { Box, Typography, IconButton, Button } from '@mui/material'

import ProjectModal from './projectModal'

const style = {
  p: 1, m: 0,
  marginRight: 'auto',
  bgcolor: 'background.light',
  borderRight: '1px solid',
  borderColor: 'gray.300',
  maxWidth: "125px",
}

const iconStyle = {
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  borderRadius: "8px",
  height: '100%',
  "& .MuiTouchRipple-child": { borderRadius: "inherit" }  // Square the hover effect
}

const projectStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  fontSize: 12
}

const ProjectBox = () => {

  const [projectData, setProjectData] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setProjectData(null);
    setProjectData({
      time: document.querySelector('#time').value,
      project: document.querySelector('#project').textContent,
      address: document.querySelector('#address').textContent
    });
    console.log(projectData);
    setOpen(false);
  };

  if (projectData) {
    return (
      <Box >
        <Button onClick={handleOpen} sx={{ m: 0, p: 0, height: '100%' }}>
          <Box sx={{ ...style, display: 'flex', py: 2, px: 2, minWidth: '125px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="body2" sx={{ ...projectStyle }}>{projectData.project}</Typography>
            <Typography variant="body2" sx={{ ...projectStyle }}>{projectData.address}</Typography>
            <Typography variant="body2" sx={{ ...projectStyle }}>{projectData.time}</Typography>
          </Box>
        </Button>
        <ProjectModal open={open} handleClose={handleClose} />
      </Box>

    )
  }

  return (
    <Box sx={style}>
      <IconButton onClick={handleOpen} sx={iconStyle}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>+</Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>Project Details</Typography>
      </IconButton>
      <ProjectModal open={open} handleClose={handleClose} />
    </Box>
  )
}

export default ProjectBox