import React, { useState, useEffect } from 'react';

import getAPIData from '../services/api/axios';

import { BlinkBlur } from "react-loading-indicators";
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/material';

import Title from '../features/dailyDispatch/Title';
import JobTables from '../features/dailyDispatch/JobTables';

const DailyDispatch = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null) //initial state is null

  useEffect(() => {
    const url = "api/v1/getjobcost/current";
    getAPIData(url, setData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Container sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
        <Box>
          <img alt="RS Logo" width="128px" height="128px" src="https://rsapp-mass-storage-prod.s3.us-west-2.amazonaws.com/static/logo_trans_fill_dark.png" />
        </Box>
        <Typography variant='h4' sx={{ my: 2 }}>R&S Daily Dispatch Page</Typography>
        <Typography variant='h5' sx={{ mb: 4 }}>Loading...</Typography>
        <Box>
          <BlinkBlur color="#121c12" size="medium" text="" textColor="" />
        </Box>
      </Container>
    );
  }

  return (
    data && <Container sx={{ minWidth: "1024px", maxWidth: "1024px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 4, pt: 4 }}>
        <Title data={data}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setData={setData}
          getData={getAPIData} />
      </Box>
      <Box sx={{ display: "block", justifyContent: "center", mt: 2, pt: 2 }}>
        <Typography variant='h4' sx={{ textAlign: "center", }}>Projects:</Typography>
        <JobTables data={data} />
      </Box>
    </Container>
  );

}

export default DailyDispatch;