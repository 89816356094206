import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DailyCost from './pages/DailyCost';
import DailyDispatch from './pages/DailyDispatch';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import rsTheme from './theme/theme';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={rsTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" exact element={<DailyDispatch />} />
          <Route path="/dailyCost" element={<DailyCost />} />
        </Routes>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);