import React, { useState } from 'react';

import { closestCorners, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Box, Button } from '@mui/material';

import Project from "./jobBox/Project";
import HorizontalLine from '../../components/ui/HorizontalLine';
import DateBar from '../../components/DateBar';

const JobTables = (props) => {

  // Some hardcoded jobs data for testing
  const [jobs, setJobs] = useState(
    [
      { id: 1, title: "648 - 1233I VL55 Darcy" },
    ]
  );

  // Function to get the index of the job position
  const getJobPosition = (id) => {
    return jobs.findIndex((job) => job.id === id);
  };

  // Swaps the jobs in the array when the drag and drop is complete
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setJobs((jobs) => {
      const originalPosition = getJobPosition(active.id);
      const newPosition = getJobPosition(over.id);

      return arrayMove(jobs, originalPosition, newPosition);
    });

  };

  // Adds a new job (with hardcoded data for now) to the jobs array and calls setJobs 
  // to update the state and reload the component
  const addJob = () => {
    const newJob = { id: jobs.length + 1, title: `New Job ${jobs.length + 1}` };
    setJobs((prevJobs) => [...prevJobs, newJob]);
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <HorizontalLine key={'Line-1'} color="black" width="95%" />
      <Box sx={{ display: 'flex', flexDirection: 'row', m: 2, maxHeight: '35px' }}>
        <Button onClick={addJob} variant="contained" sx={{ bgcolor: 'primary', color: 'white', width: '190px' }}>
          Add New Job
        </Button>
        <DateBar date={props.data["frontend_data"]["current_week"]} />
      </Box>
      <Box>
        {/* Parent component to allow the drag and drop elements to be sortable */}
        <SortableContext items={jobs} strategy={verticalListSortingStrategy}>
          {jobs.map((job) => (
            <Project key={job.id} id={job.id} title={job.title} />
          ))}
        </SortableContext>
      </Box >
      <HorizontalLine key={'Line-2'} color="black" width="95%" />
    </DndContext>
  );
};

export default JobTables;