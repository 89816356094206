import { extendTheme } from "@chakra-ui/react";
import styles from "./chakraStyles";

/**
 * Chakra UI Theme.
 * DEPRECATED: This file is ONLY used for the tables in daily cost page which were built
 * BEFORE the implementation of Material UI. Everthing else should now be built in MUI.
 */

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  styles,
  config,
});

export default theme;