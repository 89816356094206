// import { useState } from "react";

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// import { Box } from '@mui/material';

import { Box, Card } from '@mui/material';
import WorkerBox from '../jobBox/workerDetails/workerBox';
import ProjectBox from '../jobBox/projectDetails/projectBox';
import CostBox from '../jobBox/costDetails/costBox';
import GrabberBox from '../../../components/ui/grabberBox';


const Job = ({ index, id, title }) => {

  // const [show, setShow] = useState(true);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    transition: {
      duration: 1000,
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const cardStyle = {
    m: 2, p: 0,
    display: 'flex',
    flexDirection: 'row',
    height: '120px'
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      key={'jobCard-' + index}
      variant="outlined"
      sx={cardStyle}>
      <GrabberBox attributes={attributes} listeners={listeners} />
      <ProjectBox />
      <Box sx={{ flexGrow: 1, textAlign: 'center', height: '85px' }}>
        {/* Worker list day boxes */}
        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row' }}>
          {[...Array(5)].map((_, i) => (
            <WorkerBox index={i} key={i} />
          ))}
        </Box>
        <CostBox />
      </Box>
    </Card>
  );
};
export default Job;