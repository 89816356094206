/**
 * Calcs the gross profit/loss table data. On frontend as it can be calced from existing
 * revenue and cost data and saves API time.
 * 
 * @param {Array} data The job data from the API
 * @returns {Array} The gross profit data for the job in Tanstack Table row format
 */

const calcGross = (data) => {

  var cost = data.data["cost"][0];
  var revenue_array = data.data["revenue"];

  var revenue = {
    "header": "Revenue",
    "Monday": 0,
    "Tuesday": 0,
    "Wednesday": 0,
    "Thursday": 0,
    "Friday": 0,
    "total": 0
  };

  var gross = {
    "header": "Gross Profit",
    "Monday": 0,
    "Tuesday": 0,
    "Wednesday": 0,
    "Thursday": 0,
    "Friday": 0,
    "total": 0
  };

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  // Calc weekly totals
  var total = 0;

  revenue_array.forEach((item) => {
    daysOfWeek.forEach((day) => {
      revenue[day] += item[day];
    });
    
  });
  daysOfWeek.forEach((day) => {
    total += revenue[day];
    gross[day] = revenue[day] - cost[day];
    gross["total"] += gross[day];
  });

  // Add percentage column
  if (total !== 0) {
    const percentage = gross["total"] / total * 100;
    gross["percentage"] = percentage;
  };
  
  return [gross];
};

export default calcGross;