import React from 'react'

import { Box, Typography, Modal, TextField, MenuItem, Button } from '@mui/material'

const ProjectModal = ({ open, handleClose }) => {

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const projectOptions = [
    { value: 'project1', label: 'Project 1' },
    { value: 'project2', label: 'Project 2' },
    { value: 'project3', label: 'Project 3' },
  ];

  const addressOptions = [
    { value: 'address1', label: 'Address 1' },
    { value: 'address2', label: 'Address 2' },
    { value: 'address3', label: 'Address 3' },
  ];

  const [selectedProject, setSelectedProject] = React.useState(projectOptions[0].value);
  const [selectedAddress, setSelectedAddress] = React.useState(addressOptions[0].value);

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Enter Project Details
        </Typography>

        <Box component="form" noValidate autoComplete="off">
          <TextField
            id='project'
            select
            label="Select Project"
            fullWidth
            margin="normal"
            value={selectedProject}
            onChange={handleProjectChange}
          >
            {projectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id='time'
            label="Start Time"
            type="time"
            fullWidth
            margin="normal"
            defaultValue="07:00"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              inputProps: {
                step: 300, // 5 min
              },
            }}
          />
          <TextField
            id='address'
            select
            label="Select Address"
            fullWidth
            margin="normal"
            value={selectedAddress}
            onChange={handleAddressChange}
          >
            {addressOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="outlined"
            color="secondary.dark"
            fullWidth
            sx={{ my: 2 }}
          >
            + Add Managers
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            fullWidth
            sx={{ mt: 2 }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ProjectModal