import React from 'react'

import { Box, Typography, IconButton, MenuItem, Menu } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const style = {
  width: '100%',
  height: '85px',
  bgcolor: 'gray.100',
  m: 0, p: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  borderRight: '1px solid',
  borderBottom: '1px solid',
  borderColor: 'gray.300',
}

const scopes = [
  { value: 'scope1', label: 'Scope 1' },
  { value: 'scope2', label: 'Scope 2' },
  { value: 'scope3', label: 'Scope 3' }
];

const WorkerBox = ({ index }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      key={index}
      sx={style}>
      <IconButton sx={{
        display: 'flex', width: '100%', flexDirection: 'row', cursor: 'pointer', borderRadius: "8px", m: 0, py: 0,
        "& .MuiTouchRipple-child": { borderRadius: "inherit" }
      }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mr: 1 }}>+</Typography>
        <Typography variant="body2">Workers</Typography>
      </IconButton>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ bgcolor: 'gray.300', width: '100%', borderRadius: 0.5, p: 0, my: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <ExpandMoreIcon />
        <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center', pr: 2 }}>Scope</Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        dense
      >
        {scopes.map((scope) => (
          <MenuItem dense key={scope.value} onClick={handleClose} ><Typography variant="body2" sx={{ minWidth: '105px' }}>{scope.label}</Typography></MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default WorkerBox