import { Box, Typography, Button } from '@mui/material';
import Card from '@mui/material/Card';

import { Link } from 'react-router-dom';

import { DAILY_DISPATCH_VERSION } from "../../services/providers/settings";

const Title = (props) => {

  return (
    <Card variant="outlined" className='glass-green' sx={{ ml: 2, p: 2, display: 'flex', flexDirection: 'column', width: "96%", boxShadow: 2 }}>
      <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
        <Typography variant="h5" >R&S Daily Dispatch Page (v{DAILY_DISPATCH_VERSION})</Typography>
        <Typography variant="h6" >
          Dispatch: <strong>{props.data["frontend_data"]["current_week"]}</strong>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" sx={{ bgcolor: 'primary.main', mr: 'auto' }}
          onClick={() => {
            var url = `api/v1/getjobcost/${props.data["frontend_data"]["prev_week"]}`;
            props.setIsLoading(true);
            props.setData(null);
            props.getData(url, props.setData).finally(() => props.setIsLoading(false));
          }}
        > Previous Dispatch
        </Button>
        <Button variant="contained" sx={{ bgcolor: 'background.light' }}>
          <Link to="/dailyCost" style={{ textDecoration: 'none', color: 'black' }}>Daily Cost Page</Link>
        </Button>
        <Button variant="contained" sx={{ bgcolor: 'primary.main', ml: 'auto', px: 4 }}
          onClick={() => {
            var url = `api/v1/getjobcost/${props.data["frontend_data"]["next_week"]}`;
            props.setIsLoading(true);
            props.setData(null);
            props.getData(url, props.setData).finally(() => props.setIsLoading(false));
          }}
        > Next Dispatch
        </Button>
      </Box>
    </Card>
  );
};
export default Title;