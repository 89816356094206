import { createTheme } from '@mui/material';

/**
 * MUI theme settings for R&S application
 */
const rsTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#002C08',
      light: '#A0CEA1',
      contrast: '#3DACFF',
      alt: '#FFFBCE',
    },
    secondary: {
      main: '#A0AFA0',
      dark: '#6D7B6D',
      contrast: '#005A77',
    },
    bold: {
      main: '#004B84',
      contrast: '#932C35',
    },
    background: {
      paper: '#f3f3f1',
      light: '#EFFDEF',
    },
    info: {
      main: '#005A77',
    },
    gray: {
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    }
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  shape: {
    borderRadius: 10,
  },
});
export default rsTheme;