/**
 * A box with a burger icon that can be grabbed and dragged.
 */

import React from 'react'

import { Box, IconButton } from '@mui/material'

const GrabberBox = ({ attributes, listeners }) => {
  return (
    <Box sx={{
      m: 0, p: 0,
      width: '50px',
      height: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      bgcolor: 'gray.300',
      borderRight: '1px solid',
      borderColor: 'gray.300',
    }} >
      <IconButton sx={{
        py: 4,
        margin: 'auto',
        borderRadius: "8px",
        "& .MuiTouchRipple-child": { borderRadius: "inherit" }  // Square the hover effect
      }}
        {...attributes}
        {...listeners}
      >
        &#9776; {/* Burger icon in text */}
      </IconButton>
    </Box>
  )
}

export default GrabberBox
