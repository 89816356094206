import React from 'react'

import { Box, Typography, IconButton } from '@mui/material'

const boxStyle = {
  width: '100%',
  bgcolor: 'gray.300',
  m: 0, p: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  borderRight: '1px solid',
  borderColor: 'gray.300',
}

const iconStyle = {
  display: 'flex',
  width: '98%',
  flexDirection: 'row',
  cursor: 'pointer',
  borderRadius: "8px",
  m: 0.5, p: 1,
  height: '25px',
  "& .MuiTouchRipple-child": { borderRadius: "inherit" }  // Square the hover effect
}

const CostBox = ({ index }) => {
  return (
    <Box sx={boxStyle}>
      <IconButton sx={iconStyle}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mr: 2 }}>+</Typography>
        <Typography variant="body2">Cost Row</Typography>
      </IconButton>
    </Box>
  )
}

export default CostBox