/**
 * Returns a date bar component that displays the five days of the week (mon-frti) as 
 * dates
 * 
 * @param {*} param0 
 */

import { Card, Box } from '@mui/material';
import React from 'react'

const DateBar = ({ date }) => {

  const getWeekDates = (startDate) => {
    const result = [];
    const date = new Date(startDate);
    const day = date.getDay();
    const diff = day === 6 ? 2 : 1; // If Saturday, start from Monday; otherwise, start from the next day
    date.setDate(date.getDate() + diff);

    for (let i = 0; i < 5; i++) {
      const formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
      });
      result.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }

    return result;
  };

  const weekDates = getWeekDates(date);

  return (
    <Card
      variant="outlined"
      sx={{
        m: 0, p: 0, ml: 2,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}>
      {weekDates.map((date, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            bgcolor: 'gray.300',
            m: 0, p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            borderRight: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'gray.400',
          }}
        >
          {date}
        </Box>
      ))}
    </Card>
  )
}

export default DateBar
